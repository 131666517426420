import styled from 'styled-components';

import { palette } from '../../theme';
import { AlertMessage } from '../AlertMessage';
import { ButtonBase } from '../Button';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';

export const StyledHeading = styled(Heading)`
    margin-bottom: 2rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        text-align: center;
    }
`;

export const StyledLink = styled.a`
    color: ${palette.primary.dark};
    font-weight: bold;
    text-decoration: none;
    & :hover {
        color: ${palette.primary.dark};
    }
`;

export const StyledAlertMessage = styled(AlertMessage)`
    margin-bottom: 2rem;
`;

export const StyledBillingAddressWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
    padding: 1.5rem 1.5rem;
    background-color: ${palette.grey[100]};
    border-radius: 1.6rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 0 5.5rem;
        padding: 2.2rem 2.5rem;
    }
`;

export const StyledInputInnerWrapper = styled.div`
    display: grid;
    font-weight: bold;
    text-align: left;
`;

export const StyledParagraph = styled(Paragraph)`
    text-align: left;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 0 5.5rem;
        text-align: center;
    }
`;

export const StyledButton = styled(ButtonBase)`
    margin: 0 0 0 auto;
    color: ${palette.primary.dark};
    font-size: 1.4rem;
    font-family: thegymgroupsans-Headline;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }

    &:hover {
        color: ${palette.primary.main};
        background: none;
    }
`;
