import styled from 'styled-components';

import { ButtonBase } from '../../Button';
import { Heading } from '../../Heading';
import { Paragraph } from '../../Paragraph';

export const StyledButtonsContainer = styled.div`
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    margin-top: 1rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: 0 6.4rem;
    }
`;

export const StyledForm = styled.form`
    width: 100%;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        max-width: 41rem;
        margin: auto;
    }
`;

export const StyledInputWrapper = styled.div`
    padding-bottom: 2rem;
`;

export const StyledSaveButton = styled(ButtonBase)`
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding-right: 3.5rem;
        padding-left: 3.5rem;
    }
`;

export const StyledHeading = styled(Heading)`
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        text-align: center;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    width: 100%;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        max-width: 56.8rem;
        margin: auto;
        text-align: center;
    }
`;
