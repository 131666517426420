import styled from 'styled-components';

export const StyledLabel = styled.label`
    display: block;
    margin-bottom: 0.5rem;
    font-weight: normal;
    font-size: 1.4rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }
`;

export const StyledInput = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing(20)};
`;
