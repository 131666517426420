import styled from 'styled-components';

export const StyledSubmitButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row-reverse;
        padding: 0 16rem;

        & > button {
            padding: 0.55rem 2.5rem;
        }
    }
`;
