import React, {
    ForwardedRef,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ButtonBase } from '../../Button';
import { TextInput } from '../../Forms/TextInput';
import { Overlay } from '../../Overlay';
import {
    iso3166CountriesList,
    iso3166UsStatesList,
} from '../BillingAddress.constants';
import {
    StyledButtonsContainer,
    StyledForm,
    StyledSaveButton,
    StyledHeading,
    StyledParagraph,
    StyledInputWrapper,
} from './BillingAddressOverlay.styled';
import { BillingAddressOverlayProperties } from './BillingAddressOverlay.types';
import { BillingAddressFields } from '@tgg/common-types';
import {
    addressLineValidator,
    cityTownValidator,
    postcodeValidator,
} from '@tgg/form-validation';
/**
 * An overlay which prompts the user to enter and submit their bulling address details.
 */
export const BillingAddressOverlay = forwardRef(
    (
        { onSubmit }: BillingAddressOverlayProperties,
        reference: ForwardedRef<{ setIsOpen: (isOpen: boolean) => void }>,
    ) => {
        const { handleSubmit, control, formState, setValue, watch } =
            useForm<BillingAddressFields>({
                mode: 'onChange',
                defaultValues: {
                    addressLine1: '',
                    addressLine2: '',
                    addressLine3: '',
                    city: '',
                    country: 'GB',
                    postCode: '',
                    state: '',
                },
            });
        const { isValid } = formState;

        const watchCountry = watch('country');

        const [isOpen, setIsOpen] = useState(false);

        useImperativeHandle(reference, () => ({
            setIsOpen,
        }));

        const handleClose = useCallback(() => {
            setIsOpen(false);
        }, []);

        const handleOnSubmit = useCallback(
            async (data: BillingAddressFields) => {
                await onSubmit(data);
                setIsOpen(false);
            },
            [onSubmit],
        );

        useEffect(() => {
            if (watchCountry === 'US') {
                setValue('state', 'AL');
            } else {
                setValue('state', '');
            }
        }, [watchCountry, setValue]);

        return (
            <div>
                {isOpen && (
                    <Overlay
                        title="Change billing address"
                        open={isOpen}
                        handleClose={handleClose}
                        centerTitleOnDesktop
                    >
                        <StyledHeading>Billing Address</StyledHeading>
                        <StyledParagraph>
                            Please make sure your credit/debit card billing
                            address is as it appears on your card statement.
                        </StyledParagraph>
                        <StyledForm
                            onSubmit={(
                                event: React.FormEvent<HTMLFormElement>,
                            ) => handleSubmit(handleOnSubmit)(event)}
                        >
                            <Controller
                                name="country"
                                control={control}
                                render={({
                                    field: { value, onChange, onBlur },
                                    fieldState: { invalid, error },
                                }) => (
                                    <StyledInputWrapper>
                                        <TextInput
                                            select
                                            label="Country"
                                            required
                                            value={value}
                                            errorMessage={error?.message}
                                            isInvalid={invalid}
                                            id="country"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                        >
                                            {Object.entries(
                                                iso3166CountriesList,
                                            ).map(([code, name]) => (
                                                <option key={code} value={code}>
                                                    {name}
                                                </option>
                                            ))}
                                        </TextInput>
                                    </StyledInputWrapper>
                                )}
                            />

                            <Controller
                                name="addressLine1"
                                control={control}
                                rules={{
                                    validate: {
                                        validator: value => {
                                            if (value.trim().length === 0) {
                                                return 'Your address is required';
                                            }

                                            return addressLineValidator(value);
                                        },
                                    },
                                }}
                                render={({
                                    field: { value, onChange, onBlur },
                                    fieldState: { invalid, isDirty, error },
                                }) => (
                                    <StyledInputWrapper>
                                        <TextInput
                                            label="Address Line 1"
                                            required
                                            value={value}
                                            errorMessage={error?.message}
                                            isInvalid={invalid}
                                            id="addressLine1"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            iconElementRight={{
                                                name:
                                                    !invalid && isDirty
                                                        ? 'tick'
                                                        : 'blank',
                                            }}
                                        />
                                    </StyledInputWrapper>
                                )}
                            />

                            <Controller
                                name="addressLine2"
                                control={control}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { isDirty },
                                }) => (
                                    <StyledInputWrapper>
                                        <TextInput
                                            label="Address Line 2"
                                            value={value as string}
                                            id="addressLine2"
                                            onChange={onChange}
                                            iconElementRight={{
                                                name: isDirty
                                                    ? 'tick'
                                                    : 'blank',
                                            }}
                                        />
                                    </StyledInputWrapper>
                                )}
                            />

                            <Controller
                                name="addressLine3"
                                control={control}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { isDirty },
                                }) => (
                                    <StyledInputWrapper>
                                        <TextInput
                                            label="Address Line 3"
                                            value={value as string}
                                            id="addressLine3"
                                            onChange={onChange}
                                            iconElementRight={{
                                                name: isDirty
                                                    ? 'tick'
                                                    : 'blank',
                                            }}
                                        />
                                    </StyledInputWrapper>
                                )}
                            />

                            <Controller
                                name="city"
                                control={control}
                                rules={{
                                    validate: {
                                        validator: cityTownValidator,
                                    },
                                }}
                                render={({
                                    field: { value, onChange, onBlur },
                                    fieldState: { invalid, isDirty, error },
                                }) => (
                                    <StyledInputWrapper>
                                        <TextInput
                                            required
                                            label="City / Town"
                                            value={value}
                                            errorMessage={error?.message}
                                            isInvalid={invalid}
                                            id="city"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            iconElementRight={{
                                                name: isDirty
                                                    ? 'tick'
                                                    : 'blank',
                                            }}
                                        />
                                    </StyledInputWrapper>
                                )}
                            />

                            {watchCountry === 'US' && (
                                <Controller
                                    name="state"
                                    control={control}
                                    render={({
                                        field: { value, onChange, onBlur },
                                        fieldState: { invalid, error },
                                    }) => (
                                        <StyledInputWrapper>
                                            <TextInput
                                                select
                                                label="State"
                                                required
                                                value={value as string}
                                                errorMessage={error?.message}
                                                isInvalid={invalid}
                                                id="state"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                            >
                                                {Object.entries(
                                                    iso3166UsStatesList,
                                                ).map(([code, name]) => (
                                                    <option
                                                        key={code}
                                                        value={code}
                                                    >
                                                        {name}
                                                    </option>
                                                ))}
                                            </TextInput>
                                        </StyledInputWrapper>
                                    )}
                                />
                            )}

                            <Controller
                                name="postCode"
                                control={control}
                                rules={{
                                    validate: {
                                        validator: postcodeValidator,
                                    },
                                }}
                                render={({
                                    field: { value, onChange, onBlur },
                                    fieldState: { invalid, isDirty, error },
                                }) => (
                                    <StyledInputWrapper>
                                        <TextInput
                                            required
                                            label="Postcode / Zip Code"
                                            value={value}
                                            errorMessage={error?.message}
                                            isInvalid={invalid}
                                            id="postCode"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            iconElementRight={{
                                                name: isDirty
                                                    ? 'tick'
                                                    : 'blank',
                                            }}
                                        />
                                    </StyledInputWrapper>
                                )}
                            />

                            <StyledButtonsContainer>
                                <ButtonBase
                                    text="Cancel"
                                    onClick={handleClose}
                                    buttonStyle="secondary"
                                />

                                <StyledSaveButton
                                    type="submit"
                                    text="Save"
                                    disabled={!isValid}
                                />
                            </StyledButtonsContainer>
                        </StyledForm>
                    </Overlay>
                )}
            </div>
        );
    },
);

BillingAddressOverlay.displayName = 'BillingAddressOverlay';
