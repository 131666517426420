import styled from 'styled-components';

import { Paragraph } from '../../Paragraph';

export const StyledInputFieldsSection = styled.div`
    min-width: 31.5rem;
    max-width: 41rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: auto;
    }
`;

export const StyledInstructionText = styled(Paragraph)`
    margin: 1rem 0 2rem 0;
    font-size: 1.4rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 2rem 0 1.1rem 0;
        font-size: 1.6rem;
    }
`;

export const StyledNarrowInputFieldsWrapper = styled.div`
    label + div {
        max-width: 15.8rem;
    }
`;
