import styled from 'styled-components';

import { Heading } from '../../../Heading';

export const StyledHeading = styled(Heading)`
    margin-bottom: 2rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        text-align: center;
    }
`;

export const StyledPaymentIcons = styled.div`
    margin-bottom: 2rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: 4rem;
        text-align: center;
    }
`;
